const sliders = () => {
  const Swiper = window.Swiper;

  // Slider intro
  const intro = document.querySelector(".js-intro-slider");

  if (intro) {
    const mySwiper = new Swiper(".js-intro-slider.swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".js-intro-slider .navigation__number--next",
        prevEl: ".js-intro-slider .navigation__number--current"
      }
    });

    //Находим кнопки с цифрами и бар
    const currentNumber = $(".js-intro-slider .navigation__number--current");
    const nextNumber = $(".js-intro-slider .navigation__number--next");
    const progressBar = $(".navigation__progress");

    //Функция заполнения бара

    function progressChange() {
      progressBar.animate({
          width: "100%"
      }, 5000);
    }
    progressChange();

    //Функция смены цифр
    function numberChange() {
      let nextSlide = $(".js-intro-slider .swiper-slide-next");
      let activeSlide = $(".js-intro-slider .swiper-slide-active");

      //Номер следующего слайда
      if (nextSlide) {
        let index = Number(nextSlide.attr("data-index")) + 1;
        nextNumber.text("0" + index);
      }

      //Номер активного слайда
      if (activeSlide) {
        let index = Number(activeSlide.attr("data-index")) + 1;
        currentNumber.text("0" + index);

        let slides = $(".js-intro-slider .swiper-slide").length;

        //Номер следующего слайда, если сейчас последний
        if (index === slides) {
          nextNumber.text("01");
        }
      }

    }

    //Событие изменения слайда
    mySwiper.on("transitionStart", function() {
      progressBar.stop();

      progressBar.animate({
        width: "0%"
      }, 0);

      progressChange();
      numberChange();
    });
  }


  // Slider logo
  const logo = document.querySelector(".js-logo-slider");

  if (logo) {
    const mySwiper = new Swiper(".js-logo-slider", {
      direction: "horizontal",
      spaceBetween: 10,
      speed: 300,
      autoplay: {
        delay: 30000,
      },
      breakpoints: {
        576: {
          loop: true,
          autoplay: {
            delay: 3500,
          },
        }
      }
    });
  }


  // Slider all goods
  const allGoods = document.querySelector(".js-all-goods-slider");

  if (allGoods) {
    const mySwiper = new Swiper(".js-all-goods-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 400,
      navigation: {
        nextEl: ".js-all-goods-slider .swiper-button-next",
        prevEl: ".js-all-goods-slider .swiper-button-prev",
      },
      breakpoints: {
        510: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      }
    });

  }

  // Slider new goods
  const newGoods = document.querySelector(".js-new-goods-slider");

  if (newGoods) {
    const mySwiper = new Swiper(".js-new-goods-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 400,
      navigation: {
        nextEl: ".js-new-goods-slider .swiper-button-next",
        prevEl: ".js-new-goods-slider .swiper-button-prev",
      },
      breakpoints: {
        510: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      }
    });

  }

  // Slider sale goods
  const saleGoods = document.querySelector(".js-sale-goods-slider");

  if (saleGoods) {
    const mySwiper = new Swiper(".js-sale-goods-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 400,
      navigation: {
        nextEl: ".js-sale-goods-slider .swiper-button-next",
        prevEl: ".js-sale-goods-slider .swiper-button-prev",
      },
      breakpoints: {
        510: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      }
    });

  }

  // Slider hits goods
  const hitsGoods = document.querySelector(".js-hits-goods-slider");

  if (hitsGoods) {
    const mySwiper = new Swiper(".js-hits-goods-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 400,
      navigation: {
        nextEl: ".js-hits-goods-slider .swiper-button-next",
        prevEl: ".js-hits-goods-slider .swiper-button-prev",
      },
      breakpoints: {
        510: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      }
    });

  }

  var galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });
  var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    thumbs: {
      swiper: galleryThumbs
    },
    navigation: {
      nextEl: '.js-gallery-slider .swiper-button-next',
      prevEl: '.js-gallery-slider .swiper-button-prev',
    }
  });

};

export default sliders;
