const headerScroll = () => {
  const main = document.querySelector("main");

  const $header = $(".header");

  if ($header) {
    const $headerSticky = $(".header-sticky");

    // Header становится fixed
    const scrollHeader = () => {
      const introTop = main.getBoundingClientRect().top;

      if (introTop === -50 || introTop < -50) {
        $headerSticky.addClass("transform");
      } else {
        $headerSticky.removeClass("transform");
      }

      const isVisible = introTop < -100;

      if (isVisible) {
        $headerSticky.addClass("is-scroll");
      } else if ($headerSticky.hasClass("is-scroll") && !isVisible) {
        $headerSticky.removeClass("is-scroll");
      }
    };

    $(window).on("scroll", scrollHeader);
  }


};

export default headerScroll;
